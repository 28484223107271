import { InformationCircle } from '@styled-icons/ionicons-solid/InformationCircle'
import { Warning as WarningIcon } from '@styled-icons/ionicons-solid/Warning'
import React from 'react'

type WarningBlockProps = {
  children: React.ReactChildren
}

export const WarningBlock = (props: WarningBlockProps) => <div
  className="bg-yellow-50 p-2 rounded font-normal flex items-start inline-block border-t-4 border-yellow-400 text-black"
>
  <WarningIcon className="mx-2 inline text-yellow-500" style={{ width: '1.5em', minWidth: '1.5em' }} />
  <div className="font-light">{props.children}</div>
</div>

export const InfoBlock = (props: WarningBlockProps) => <div
  className="bg-blue-50 p-2 rounded font-normal flex items-start inline-block border-t-4 border-blue-400 text-black"
>
  <InformationCircle className="mx-2 mt-0.5 inline text-blue-500" style={{ width: '2.5em', minWidth: '2.5em' }} />
  <div className="font-light">{props.children}</div>
</div>

