import { MDXProvider } from '@mdx-js/react'
import classNames from 'classnames'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { InfoBlock, WarningBlock } from 'components/helpers'
import MDXDocumentationLayout from 'components/layouts/MDXDocumentationLayout'
import MyImage from 'components/MyImage'
import { Link, PageProps } from 'gatsby'
import { SitePageContext } from 'types/graphql'
import React from 'react'

const shortcodes = { Link, MyImage, WarningBlock, InfoBlock }

// Entry point for .mdx pages #anGBG#
export default function MDXLayout(props: Omit<PageProps<{}, SitePageContext>, 'children'> & { children: React.ReactNodeArray }) {
  // fixing gatsby's children typing which should not be undefined                ☝️
  if (props.location.pathname.includes('/documentation/')) {
    return <MDXProvider components={shortcodes}><MDXDocumentationLayout {...props} /></MDXProvider>
  }
  const noProse = props.pageContext?.frontmatter?.noProse
  return (
    <div>
      <Header fixed={false} />
      <main
        className={classNames('prose sm:prose-sm md:prose-lg lg:prose-xl xl:prose-2xl',
          'mt-10 mx-auto container-w')}
      >
        {props.children}
        <MDXProvider components={shortcodes}>{props.children}</MDXProvider>
        <Footer />
      </main>
    </div>
  )
}
