import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import useWindowSize from 'components/hooks/useWindowSize'
import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'
import { DocumentationMenuQuery } from 'types/graphql'
import React, { useState } from 'react'

type Sections = Array<{ url: string, title: string, visible?: boolean }>

export default function MDXDocumentationLayout({
                                                 children,
                                                 location
                                               }: { children: React.ReactNodeArray } & Omit<PageProps, 'children'>) {

  const { height: windowHeight } = useWindowSize()
  const [visibleSlug, setVisibleSlug] = useState<string>()
  const query = useStaticQuery<DocumentationMenuQuery>(graphql`
      query DocumentationMenu {
          sitePage {
              path
          }
          mdx {
              frontmatter {
                  title
              }
          }
          allMdx(filter: {slug: {glob: "documentation/**"}, frontmatter: {disabled: {ne: true}}}) {
              nodes {
                  slug
                  frontmatter {
                      title
                      weight
                      sectionBefore
                  }
                  # stop at h2 to exclude "h3 steps" links
                  tableOfContents(maxDepth: 2)
              }
          }
      }
  `)
  const nodes = query.allMdx.nodes
  nodes.sort((a, b) => (a.frontmatter!.weight ?? 10000) - (b.frontmatter!.weight ?? 10000))

  // const activeNode = nodes.find(n => `/${n.slug}` === location.pathname)

  const activeNodeIdx = nodes.findIndex(n => `/${n.slug}` === location.pathname)
  const activeNode = nodes[activeNodeIdx]
  const nextNode = activeNodeIdx < nodes.length ? nodes[activeNodeIdx + 1] : undefined

  // for current, active node (ex: Quick Tour)
  // https://www.npmjs.com/package/@n8tb1t/use-scroll-position
  useScrollPosition(({ prevPos, currPos }) => {
    // only consider the active node
    // Examine the h2 tags
    if (activeNode) {
      const tag = 'h2'
      // Find the element that's near the middle of the window's height #R7WKb#
      Array.from(document.getElementsByTagName(tag)).forEach(elem => {
        const y = elem.getBoundingClientRect().y
        if (y > 0 && y < windowHeight / 2) {
          const sections: Sections = activeNode.tableOfContents.items
          sections.forEach(section => {
            if ('#' + elem.id === section.url) { // #EsFJ4#
              setVisibleSlug(section.url)
            }
          })
        }
      })
    }
  }, [], undefined, undefined, 250)

  return (
    <div>
      <Header fixed />
      <main
        className="py-24 container-w"
      >
        <div
          // on mobile, display the menu above the content (flex-col)
          className="flex flex-col sm:flex-row"
        >
          <div id="sidebar"
            // on mobile, display the menu above the content (flex-col)
            // on desktop, left column with adjusted width #bz8hb#
               className="flex-none h-full w-full
               sm:ml-4 sm:-mt-3 sm:w-1/5 lg:w-1/4 sm:mr-10
               text-xs sm:text-base lg:text-lg text-gray-400"
               style={{
                 zIndex: 0,  // fix for sidebar preventing click on codestory logo when scroll to bottom of page
               }}
          >
            <ul
              // when displayed above (mobile), use an horizontal list.
              // on desktop, a sticky menu in the sidebar
              className="flex flex-row justify-center mb-4 flex-wrap leading-3
                         sm:fixed sm:flex-none sm:block sm:leading-normal
                         sm:pr-32 sm:max-w-xs md:pr-0 md:max-w-md
                         " // #7tsQr# ☝️
            >
              {nodes.map(node => {
                const subItems = activeNode === node && node.tableOfContents.items as Sections
                const sectionBefore = node.frontmatter!.sectionBefore
                let sectionBeforeLi: React.ReactNode | undefined
                if (sectionBefore) {
                  sectionBeforeLi = <li
                    className="hidden sm:block text-gray-500 mt-4 mb-2 uppercase tracking-wide font-bold"
                    style={{ fontSize: '0.9em' }}
                  >{sectionBefore}</li>
                }
                return <React.Fragment key={node.slug}>
                  {sectionBeforeLi}
                  <li
                    // no whitespace break when displayed in an horizontal list
                    className="mb-2
                  whitespace-nowrap mx-1
                  sm:whitespace-normal sm:mx-0
                  leading-5
                  "
                  >
                    <Link activeClassName="text-blue-400"
                          to={'/' + node.slug}>{node.frontmatter!.title}</Link>{
                    subItems ? <ul
                      // don't display level > 1 items (page anchors) on mobile
                      className="hidden sm:block"
                    >{subItems!.map((item, idx) => <li
                      key={idx}
                      className={classNames({ 'text-blue-400': item.url === visibleSlug /* #fLkPB# */})}><a
                      href={item.url} style={{ fontSize: '95%', lineHeight: '1em' }}>{item.title}</a>
                    </li>)}</ul> : undefined
                  }
                  </li>
                </React.Fragment>
              })}
            </ul>

          </div>
          <div id="content-wrapper"
               className="
               documentation
               border-gray-300
               sm:pl-10
               lg:pl-0
               prose prose-sm sm:prose lg:prose-lg xl:prose-xl
               flex-auto sm:static sm:max-h-full"
          >
            <h1>{activeNode?.frontmatter?.title}</h1>
            {children}

            <div>{nextNode && <div className="mt-3 pt-3 text-base">
              <Link to={'/' + nextNode.slug!} className="next-previous float-right">{nextNode!.frontmatter!.title}<span
                className="ml-2">→</span></Link>
            </div>}</div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}
