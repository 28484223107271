import useScreen from './useScreen'

interface WindowSize {
  width: number
  height: number
}

function useWindowSize(): WindowSize {
  const screen = useScreen()
  // https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  if (typeof window !== `undefined`) {
    return {
      width: window?.innerWidth || 0,
      height: window?.innerHeight || 0,
    }
  }
  return {
    width: 0,
    height: 0
  }
}

export default useWindowSize
