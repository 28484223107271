import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MyImageQuery } from 'types/graphql'
import React, { useState } from 'react'
import Popup from 'reactjs-popup'

type Props = {
  src: string,
  alt: string,
  preset?: 'default' | 'no-border',
  className?: string, // caution, tailwind is likely to purge classes used here!
  style?: React.CSSProperties,
  children?: React.ReactChildren | string,
}

function baseName(str: string) {
  let base = new String(str).substring(str.lastIndexOf('/') + 1)
  if (base.lastIndexOf('.') != -1)
    base = base.substring(0, base.lastIndexOf('.'))
  return base
}

/**
 * Workaround to use sharp images in mdx.
 * Make our own component that will query all sharp images, and then return the one we are looking for.
 * https://github.com/wesbos/Gatsby-Workshop/blob/master/notes/04%20-%20Images.md
 */
export default function MyImage({ src, alt, className, children, preset, style }: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const { allImageSharp, other } = useStaticQuery<MyImageQuery>(graphql`
      query MyImage {
          allImageSharp {
              edges {
                  node {
                      gatsbyImageData(webpOptions: {quality: 80})
                      original {
                          width
                          src
                      }
                      fluid {
                          originalName
                      }
                  }
              }
          }
          other: allFile(filter: {extension: {in: ["gif","svg"]}}) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `)

  let innerImg: React.ReactNode | undefined
  let largeImg: React.ReactNode | undefined

  const isSVG = src.endsWith('.svg')
  const isGif = src.endsWith('.gif')
  if (isSVG || isGif) {
    const img = other.nodes.find(edge => edge.name === baseName(src))
    if (img) {
      innerImg = <img src={img.publicURL!} style={{margin: 'auto', maxWidth: '65%', ...style}} />
      largeImg = innerImg
    }
  } else {
    const image = allImageSharp.edges.find(
      edge => edge.node.fluid!.originalName === src
      // edge => edge.node.
    )
    if (image) {
      innerImg = <GatsbyImage image={image.node.gatsbyImageData} alt={alt}
                              className={'my-image-image ' + (preset ?? 'default')}
                              imgStyle={{ objectPosition: 'top' }} />


      // innerImg = <Img fluid={image.node.fluid! as FluidObject}
      //                 className={'my-image-image ' + (preset ?? 'default')}
      //                 alt={alt}
      //                 imgStyle={{ objectPosition: 'top' }} />
      largeImg = <GatsbyImage image={image.node.gatsbyImageData} alt={alt}
                      imgStyle={{ objectPosition: 'top' }}
        // retina screen caps may appear twice than they should when they are smaller
        // than the viewport, because original.width is retina size
        // Limit this to 0.75 so it can look bigger but not 2x bigger;
                      style={{ margin: 'auto', maxWidth: image.node.original!.width! * 0.75 }}
      />
      // largeImg = <Img fluid={image.node.fluid! as FluidObject} alt={alt}
      //                 imgStyle={{ objectPosition: 'top' }}
      //   // retina screen caps may appear twice than they should when they are smaller
      //   // than the viewport, because original.width is retina size
      //   // Limit this to 0.75 so it can look bigger but not 2x bigger;
      //                 style={{ margin: 'auto', maxWidth: image.node.original!.width! * 0.75 }}
      // />
    }
  }
  if (!innerImg) {
    return <div>src + ' not found'</div>
  }

  return <>
    <div className={classNames('my-image', className)} style={style}>
      <div onClick={() => setOpen(true)}>
        {innerImg}
        {children && <div className="legend">{children}</div>}
      </div>
    </div>
    <Popup
      open={open} position="center center" onClose={() => setOpen(false)}
      contentStyle={{ width: 'fit-content', background: isSVG ? 'white' : 'none', border: 'none' }}
    >
      <div onClick={() => setOpen(false)}>
        {/*<img src={image.node.original.src} alt={alt}*/}
        {/*     style={{ margin: 'auto', objectPosition: 'top' }}*/}
        {/*/>*/}
        {largeImg}
      </div>
    </Popup></>
}
