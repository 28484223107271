import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Move the pointer to the left of the page, so the small cross shows up. Click and add an `}<em parentName="p">{`image`}</em>{` widget.`}</p>
    <MyImage src="creating-notes-4.png" alt="Creating Notes 4" mdxType="MyImage" />
    <p>{`You can then either drag & drop an image from your system, click the drop zone to open a file browser, or select the Image View and paste from the clipboard.`}</p>
    <div className="grid grid-cols-2 gap-3">
  <MyImage src="creating-notes-5.png" alt="Creating Notes 5" mdxType="MyImage" />
  <MyImage src="creating-notes-6.png" alt="Creating Notes 6" mdxType="MyImage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      